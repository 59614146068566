import { any, isNil } from 'ramda'
import { CountdownComponent } from './countdown.component'
import { isElement } from '../../vendor/lodash'
import { parseDecimal } from '../../algolia/lib/route-state.lib'

/**
 * Attempts to create a countdown component.
 *
 * @throws {Error} Throws errors if the input element is insufficient; if it is itself not an element, or
 *                 not all required child elements exist; and if the remaining seconds data is not present.
 */
export function createCountdown(container: HTMLElement): CountdownComponent {

    if (! isElement(container)) {
        throw new Error('countdown container must be an HTMLElement')
    }

    const startsAtSeconds = parseDecimal(container.dataset.secondsRemaining!)

    if (Number.isNaN(startsAtSeconds)) {
        throw new Error(`Could not get number for remaining seconds: ${startsAtSeconds}`)
    }

    const query = (selector: string) => container.querySelector<HTMLElement>(`.countdown--${selector}`)!

    const daysElement = query('days')
    const hoursElement = query('hours')
    const minutesElement = query('minutes')
    const secondsElement = query('seconds')

    if (any(isNil, [daysElement, hoursElement, minutesElement, secondsElement])) {
        throw new Error('could not find all required child-elements')
    }

    return new CountdownComponent(startsAtSeconds, {
        daysElement,
        hoursElement,
        minutesElement,
        secondsElement,
    })
}
