import Cookies from 'js-cookie'
import { Modal } from '@app-components/modal/modal.component'
import { innerText, onDOMReady, queryAll } from 'lambda-dom'

// ------------------------------------------------------------------------------
//      Config
// ------------------------------------------------------------------------------

const cookieKey = 'browser-support-warned'
const cookieVal = 'TRUE'

// ------------------------------------------------------------------------------
//      Functions
// ------------------------------------------------------------------------------

function IEVersion(): 11 | 10 | 9 | null {
    const ua = window.navigator.userAgent

    if (ua.indexOf('Trident/7.0') > -1) {
        return 11
    }

    if (ua.indexOf('Trident/6.0') > -1) {
        return 10
    }

    if (ua.indexOf('Trident/5.0') > -1) {
        return 9
    }

    return null
}

// ------------------------------------------------------------------------------
//      Modal
// ------------------------------------------------------------------------------

class BrowserSupportModal extends Modal {
    public show(): this {
        this.setVersion()
        return super.show()
    }

    protected setVersion(): void {
        queryAll<HTMLElement>('[data-ie-version]', this.element).forEach(
            innerText(String(IEVersion())),
        )
    }
}

// ------------------------------------------------------------------------------
//      Runtime
// ----------------------------------------------------------------s--------------

onDOMReady(() => {
    if (IEVersion() !== null && Cookies.get(cookieKey) !== cookieVal) {
        const browserSupportModal = BrowserSupportModal.create('browser-support-modal')
        browserSupportModal.closeButtonClicks$.subscribe(() => Cookies.set(cookieKey, cookieVal, {
            expires: 30,
        }))
        browserSupportModal.show()
    }
})
