import { interval, Observable } from 'rxjs'
import { map, takeWhile } from 'rxjs/operators'
import { divMod } from '@app-lib/arithmetic.lib'
import { SECONDS_PER_DAY, SECONDS_PER_HOUR, SECONDS_PER_MINUTE } from '@app-lib/time.lib'

export interface Duration {
    days: number
    hours: number
    minutes: number
    seconds: number
}

export class CountdownComponent {

    private readonly durationRemaining$: Observable<Duration>

    constructor(
        remainingSecondsAtStart: number,
        private readonly segmentElements: {
            daysElement: HTMLElement
            hoursElement: HTMLElement
            minutesElement: HTMLElement
            secondsElement: HTMLElement
        },
    ) {
        this.durationRemaining$ = interval(1000).pipe(
            takeWhile((n) => n <= remainingSecondsAtStart),
            map((n) => this.secondsToDuration(remainingSecondsAtStart - n)),
        )

        this.durationRemaining$.subscribe(({ days, hours, minutes, seconds }) => {
            this.segmentElements.daysElement.innerText = this.formatTimeSegment(days)
            this.segmentElements.hoursElement.innerText = this.formatTimeSegment(hours)
            this.segmentElements.minutesElement.innerText = this.formatTimeSegment(minutes)
            this.segmentElements.secondsElement.innerText = this.formatTimeSegment(seconds)
        })
    }

    /**
     * Create a Duration object from given total amount of seconds
     */
    private secondsToDuration(totalSeconds: number): Duration {
        const [days, rem1] = divMod(totalSeconds, SECONDS_PER_DAY)
        const [hours, rem2] = divMod(rem1, SECONDS_PER_HOUR)
        const [minutes, seconds] = divMod(rem2, SECONDS_PER_MINUTE)

        return { days, hours, minutes, seconds }
    }

    /**
     * Converts given number to a string, padded with a leading zero if appropriate.
     */
    private formatTimeSegment(segment: number) {
        return segment < 10 ? `0${segment}` : String(segment)
    }
}
