import { FlowboxInitializerConfig } from '@app-services/flowbox/flowbox.types'

export class FlowboxService {
    private config: FlowboxInitializerConfig

    constructor(config: FlowboxInitializerConfig) {
        this.config = {
            initializeTags: config.initializeTags ?? false,
            ...config,
        }

        this.initialize()

        if (this.config.initializeTags) {
            this.initTags()
        }
    }

    private initialize(): void {
        if (! this.config.key) {
            console.warn('Flowbox key is not defined.')
            return
        }

        window.flowboxInitializers = window.flowboxInitializers || []

        window.flowboxInitializers.push(this.config)
    }

    private initTags(): void {
        const buttons = document.querySelectorAll<HTMLButtonElement>('button[data-tag]')

        for (const button of buttons) {
            button.addEventListener('click', () => {
                this.updateFlow(button.dataset.tag!)
                this.clearButtonClasses(buttons)
                button.classList.add('active')
            })
        }
    }

    private updateFlow(tag: string): void {
        window.flowbox('update', {
            key: this.config.key,
            tags: [tag],
            tagsOperator: 'all',
        })
    }

    private clearButtonClasses(buttons: NodeListOf<HTMLButtonElement>): void {
        for (const button of buttons) {
            button.classList.remove('active')
        }
    }
}
