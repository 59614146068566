/**
 * The hour numbers (0 to 23)
 */
export type Hour =
    | 0
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 19
    | 20
    | 21
    | 22
    | 23

export type ISODayNumber =
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7

export const SECONDS_PER_MINUTE = 60
export const SECONDS_PER_HOUR = SECONDS_PER_MINUTE * 60
export const SECONDS_PER_DAY = SECONDS_PER_HOUR * 24

export function getHours(date: Date): Hour {
    return date.getHours() as Hour
}

export function getISODayNumber(date: Date): ISODayNumber {
    return ((date.getDay() + 6) % 7) + 1 as ISODayNumber
}

export function getCurrentHour(): Hour {
    return getHours(new Date())
}
