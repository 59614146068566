import { flatpickr } from '@app-bootstrap/flatpickr.bootstrap'
import { mergeRight } from 'ramda'

export const defaultConfig: flatpickr.Options.Options = {
    altInput: true,
    altFormat: 'j F Y',
    dateFormat: 'd-m-Y',
}

export class DatePicker {

    private readonly pickerConfig: flatpickr.Options.Options
    private readonly picker: flatpickr.Instance

    constructor(
        private readonly element: HTMLElement,
        options: flatpickr.Options.Options = {},
    ) {
        this.pickerConfig = mergeRight(defaultConfig, options)
        this.picker = this.createPicker()
    }

    createPicker() {
        return flatpickr(this.element, this.pickerConfig)
    }

    destroy() {
        this.picker.destroy()
    }
}
