import { mediaQuery, viewportRanges } from '@app-bootstrap/viewport-tracking.bootstrap'

const isMobileViewport = window
    .matchMedia(mediaQuery({ min: viewportRanges.small.min, max: viewportRanges.medium.max }))
    .matches

if (isMobileViewport) {
    const closeMenuBtn               = document.querySelector('.mobile-menu--close') as HTMLElement
    const toggleMobileMenu           = document.querySelector('#toggle-mobile-menu') as HTMLInputElement
    const mobileHeaderPreviousButton = document.querySelector('.mobile-menu--prev') as HTMLButtonElement
    const navBackDrop                = document.querySelector('.nav-mask-mobile') as HTMLElement
    const menuItemsContainerId       = 'nav-root'

    /**
     * This feature closes the mobile hamburger menu.
     */

    const closeMobileNav = (): void => {
        toggleMobileMenu.checked = false
    }

    closeMenuBtn.addEventListener('click', closeMobileNav)
    navBackDrop.addEventListener('click', closeMobileNav)

    /**
     * This feature enables the previous button at the mobile header.
     */

    // Replace the css-only feature for a better experience with js
    const featureElements = document.querySelectorAll<HTMLElement>('.no-js')
    featureElements.forEach((el) => el.classList.remove('no-js'))

    // Remove the default back buttons for a better experience with js
    const backButtons = document.querySelectorAll<HTMLElement>('.nav-back')
    backButtons.forEach((btn: HTMLElement) => btn.style.display = 'none')

    mobileHeaderPreviousButton.addEventListener('click', () => {
        const activeMenus = document
            .querySelectorAll<HTMLElement>('.site-header--bottom--content .active')
        const lastActiveMenu = activeMenus[activeMenus.length - 1]
        lastActiveMenu.classList.remove('active')

        setTimeout(() => {
            lastActiveMenu.classList.remove('scrollable')
        }, 200)

        // Show the previous active menu
        const parentMenuOfActiveMenu = lastActiveMenu.parentNode?.parentNode as Element | null

        if (parentMenuOfActiveMenu) {
            parentMenuOfActiveMenu.classList.remove('shifted')

            setTimeout(() => {
                parentMenuOfActiveMenu.classList.add('scrollable')
            }, 200)

            if (parentMenuOfActiveMenu.id === menuItemsContainerId) {
                mobileHeaderPreviousButton.classList.remove('active')
            }
        }
    })

    const menuItems = document.querySelectorAll<HTMLElement>('.mobile-menu--item')
    menuItems.forEach((item: HTMLLinkElement) => {

        const itemHasPureNavigationId = item.href.includes('#nav-')
        if (itemHasPureNavigationId) {
            item.removeAttribute('href')
        }

        if (! item.hasAttribute('href')) {
            item.addEventListener('click', (event) => {
                event.preventDefault()
                mobileHeaderPreviousButton.classList.add('active')

                const activeMenu = item.parentNode?.parentNode as Element | null
                if (activeMenu) {
                    activeMenu.classList.add('shifted')
                    activeMenu.classList.remove('scrollable')
                }

                const nextActiveMenu = item.nextElementSibling as Element | null
                if (nextActiveMenu) {
                    nextActiveMenu.classList.add('active')
                    nextActiveMenu.classList.add('scrollable')
                }
            })
        }
    })
}
