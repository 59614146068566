import { AjaxFormHandler } from '@app-services'
import { AjaxFormFactory } from '@app-factories'

export class LoginPage {

    private requestPasswordResetForm: HTMLFormElement

    constructor(
        private readonly ajaxFormFactory: AjaxFormFactory,
    ) {
        this.initializePasswordReset()
    }

    private initializePasswordReset(): void {
        this.requestPasswordResetForm = document.querySelector('#request-password-reset') as HTMLFormElement

        if (this.requestPasswordResetForm) {
            const ajaxFormHandler: AjaxFormHandler = this.ajaxFormFactory.createInstance(this.requestPasswordResetForm)

            this.requestPasswordResetForm.addEventListener('response', (): void => {
                $('#reset-password-link-sent-modal')?.foundation('open')
            })

            this.requestPasswordResetForm.addEventListener('error', (error: CustomEvent) => {
                Object.keys(error.detail.body).forEach((key: string) => {
                    ajaxFormHandler.updateErrorMessage(key, error.detail.body[key])
                })
            })
        }
    }
}
